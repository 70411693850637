.css-1r9jet7 {
    justify-content: center !important;
}
.css-1191obr-MuiPaper-root-MuiAppBar-root {
    background-color: white !important;
}
.css-19z1ozs-MuiPaper-root-MuiAppBar-root {
    background-color: white !important;
}


/* .css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root, .css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root {
    border-radius: 12px !important;
} */

.css-zxdg2z {
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    padding: 0;
}