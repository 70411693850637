@tailwind base;
@tailwind components;
@tailwind utilities;

.responsive-width-margin {
    @apply w-full px-[16px] sm:w-[640px] md:w-[768px] md:px-[32px] lg:w-[1024px] lg:px-[48px] xl:w-[1440px] xl:px-[80px];
  }
.responsive-margin {
    @apply w-full px-[16px] md:px-[32px] lg:px-[48px] xl:px-[80px];
  }
  .flex-center {
    @apply flex justify-center
  }
  
.btn {
  @apply flex justify-center items-center mt-2 p-2 h-[36px] w-full font-bold  uppercase rounded-full  text-[14px];
}

.btn-black {
    @apply text-white bg-black hover:border-0 hover:bg-black hover:text-white;
}
.btn-bordered {
    @apply border-[1px] border-black;
}

.btn-disabled {
    @apply text-black opacity-20 bg-white hover:border-0 hover:bg-white hover:text-black;
}
